import React, { useContext, useState } from 'react'
import {
	Button,
	Select,
	MenuItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	InputLabel,
	FormControl,
	Typography,
  Grid,
  Divider
  } from '@material-ui/core';
import * as toGeoJSON from 'togeojson';
import JSZip from 'jszip';
import { UserContext } from '../../../context/UserProvider';
import { db } from '../../../config/firebase';


export const SelectCropField = ({ setGeoJsonData,setDrawPolygon,setDrawingMode,setSelectedPolygon,setImageLoaded,setDateOfImage,
  setIndiceName,setTypeAddPolygon, typeAddPolygon,drawnPolygon,setDrawnPolygon }) => {
	const [open, setOpen] = useState(false);
  const { usuario } = useContext(UserContext)
  const [selectorValue, setSelectorValue] = useState('');
  const [namesOfPolygons, setNamesOfPolygons] = useState([]);
  const [indiceSeleceted, setIndiceSeleceted] = useState("NDVI")

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSelectorChange = (event) => {
		setSelectorValue(event.target.value);
    setSelectedPolygon(event.target.value);
    setImageLoaded(false)
    setDateOfImage('')
	};

  const handleIndiceSelect = (event) => {
    setIndiceSeleceted(event.target.value)
    setIndiceName(event.target.value)
    setImageLoaded(false)
    setDateOfImage('')
  }

  const onFileChange = async(event) => {
    const file = event.target.files[0];
    const addr = `${usuario.username}/satelliteAnalysis/cropPolygons`;
    const docRef = db.collection(addr).doc('polygonData');
    if (drawnPolygon) {
      drawnPolygon.setMap(null);
      setDrawnPolygon(null); // Limpia la referencia
    }

    if (file) {
      setTypeAddPolygon("file");
      if (file.name.endsWith('.kml')) {
        const reader = new FileReader();
        const arrayOfNames = [];
        const arrayOfPolygons = [];

        reader.onload = async function (e) {
          const text = e.target.result;

          // Parsear el contenido KML a DOM
          const parser = new DOMParser();
          const kmlDoc = parser.parseFromString(text, 'text/xml');

          // Convertir KML a GeoJSON
          const geoJson = toGeoJSON.kml(kmlDoc);
          const fileDataArray = geoJson.features;
          fileDataArray.forEach((item) => {
            if (item.geometry.type === "Polygon") {
              arrayOfNames.push(item.properties.name)
              arrayOfPolygons.push(item)
            }
          });
          
          geoJson.features = [...arrayOfPolygons]
          setNamesOfPolygons([...arrayOfNames]);

          setGeoJsonData(geoJson);
          // console.log("Esto es geoJson:",geoJson)
          // const geoJsonData = {features: geoJson.features}
          // await docRef.set({ geoJsonData })
          setOpen(false);
          
        };

        reader.readAsText(file);
      } else if (file.name.endsWith('.kmz')) {
        const reader = new FileReader();
        
        const arrayOfNames = [];
        const arrayOfPolygons = [];

        reader.onload = function (e) {
          const arrayBuffer = e.target.result;

          JSZip.loadAsync(arrayBuffer).then(async function (zip) {
            // Asume que el primer archivo en el zip es el archivo KML
            const kmlFileName = Object.keys(zip.files)[0];
            zip.files[kmlFileName]
              .async('string')
              .then(async function (kmlText) {
                const parser = new DOMParser();
                const kmlDoc = parser.parseFromString(kmlText, 'text/xml');

                // Convertir KML a GeoJSON
                const geoJson = toGeoJSON.kml(kmlDoc);
                const fileDataArray = geoJson.features;
                fileDataArray.forEach((item) => {
                  if (item.geometry.type === "Polygon") {
                    arrayOfNames.push(item.properties.name)
                    arrayOfPolygons.push(item)
                  }
                });
                
                geoJson.features = [...arrayOfPolygons]
                setNamesOfPolygons([...arrayOfNames]);
                
                setGeoJsonData(geoJson);
                // await docRef.set({ geoJson })
                setOpen(false);
              });
          });
        };

        reader.readAsArrayBuffer(file);
      } else {
        alert('Formato de archivo no soportado.');
      }
    }
  };
	
  return (
	  <div>
      <div style={{ textAlign: 'center' }}>
        <Button variant="contained" size='small' color="primary" onClick={handleOpen}>
          Agregar campo
        </Button>
      </div>

      <div style={{ marginBottom: "5px", marginTop: "5px"}}>
        <Divider />
      </div>
        
      {namesOfPolygons.length !== 0 && (
        
        <div style={{ marginTop: "15px", marginBottom: "15px"}}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel id="mi-selector-label">Elige un campo</InputLabel>
          <Select
            labelId="mi-selector-label"
            id="mi-selector"
            value={selectorValue}
            onChange={handleSelectorChange}
            disabled={namesOfPolygons.length === 0}
          >
            {namesOfPolygons?.map((name,index) => (
              <MenuItem key={index} value={name}>{name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
        )}
        {typeAddPolygon !== "" && (
          <div style={{ marginTop: "15px", marginBottom: "15px"}}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="indiceName-label">Elige un índice</InputLabel>
            <Select
              labelId="indiceSelect"
              id="indice-name"
              value={indiceSeleceted}
              onChange={handleIndiceSelect}
              // disabled={namesOfPolygons.length === 0}
            >
              <MenuItem value={"NDVI"}>NDVI</MenuItem>
              <MenuItem value={"NDMI"}>NDMI</MenuItem>
              {/* <MenuItem value={"MSAVI"}>MSAVI</MenuItem> */}
            </Select>
          </FormControl>
          </div>
        )}
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Agregar Campo</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">Seleccione una opción:</Typography>

          {/* Opción para subir archivo */}
          <input
            accept=".kml,.kmz"
            style={{ display: 'none' }}
            id="subir-archivo"
            type="file"
            onChange={onFileChange}
          />
          <label htmlFor="subir-archivo">
            <Button variant="contained" component="span" color="primary" style={{ marginTop: 16 }}>
              Subir archivo (.kml, .kmz)
            </Button>
          </label>

          {/* Opción para dibujar polígono */}
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: 16, marginLeft: 16 }}
            onClick={() => {
              // Lógica para dibujar polígono
              setDrawPolygon(true)
              setDrawingMode('polygon')
              console.log('Dibujar polígono');
              setTypeAddPolygon("drawing");
              handleClose();
            }}
          >
            Dibujar polígono
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
