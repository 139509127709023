import React from 'react';
import {withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';

import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';




const PrettoSlider = withStyles({
  root: {
    color: 'primary',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);


const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  input: {
    width: 42,
  },
});

export default function InputSlider(props) {
  const classes = useStyles();



  return (
    <div className={classes.root}>
      

      <Grid container spacing={2} alignItems="center">

        <Grid item xs={12} md={6}>
          {props.label}<OfflineBoltIcon color="primary"/>  
        </Grid>

      


        <Grid item xs={10} md={5}>
          <PrettoSlider 
          valueLabelDisplay="auto" 
          aria-label="pretto slider" 
          //defaultValue={20}
          value={typeof props.value === 'number' ? props.value : 0}
          onChange={props.handleSliderChange}
          aria-labelledby="input-slider" 
          step={props.step?props.step:1}
          min={props.min?props.min:0}
          max={props.max?props.max:100}
          />
        </Grid>

        <Grid item xs={2} md={1}>
          <Input
            className={classes.input}
            value={props.value}
            margin="dense"
            onChange={props.handleInputChange}
            onBlur={props.handleBlur}
            inputProps={{
              step: props.step?props.step:1,
              min: props.min?props.min:0,
              max: props.max?props.max:100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
        

      </Grid>
    </div>
  );
}