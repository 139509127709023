import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, makeStyles, MenuItem, Select, Step, StepLabel, Stepper, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { MultiCropContext } from '../../../../context/MultiCropContext/MultiCropContext';
import { UserContext } from '../../../../context/UserProvider';
import { MapForCrop } from '../../Maps/MapForCrop';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { cropOtions, typeOfSoil, typeOfSystem, variantOptions } from '../../../../constants/CropsAndVariantOptions';
import { es } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
	  minWidth: '70vw', // Asegura un mínimo de ancho para el diálogo
	  maxWidth: '80vw', // Un máximo para evitar que sea demasiado grande
	  [theme.breakpoints.down('xs')]: {
		minWidth: '80vw', // Para pantallas pequeñas, ocupa más espacio
	  },
	},
	closeButton: {
	  position: 'absolute',
	  right: theme.spacing(1),
	  top: theme.spacing(1),
	  color: theme.palette.grey[500],
	},
	root: {
		'& .MuiTextField-root': {
		  margin: theme.spacing(1),
		  
		},
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		position: 'relative',
		width: "30%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('sm')]: {
			width: "100%", // Ocupa todo el ancho en pantallas pequeñas
		},
	},
	formControl: {
		margin: theme.spacing(1),
		position: 'relative',
		width: "100%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('sm')]: {
			width: "100%", // Ocupa todo el ancho en pantallas pequeñas
		},
	},
	container: {
		padding: theme.spacing(4),
		backgroundColor: "#FFFFFF",
		textAlign: "center",
	},
	button: {
		width: "150px", // Se amplió el ancho para ajustarse mejor
		height: "130px", // Se incrementó la altura para mejor alineación
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#cdcdcd",
		borderRadius: "12px",
		boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
		margin: theme.spacing(2),
		padding: theme.spacing(2),
		'&:hover': {
			backgroundColor: "#5BB033",
		},
	},
	selectedButton: {
		backgroundColor: "#5BB033", // Color para indicar selección
		border: "2px solid #3B3C43", // Resalta el botón seleccionado
	},
	icon: {
		width: "60px",
		height: "60px",
		marginBottom: theme.spacing(1),
	},
	label: {
		//fontSize: "14px",
		fontWeight: "bold",
		//color: "#555555",
		textAlign: "center",
		//wordBreak: "break-word", // Permite que el texto largo se ajuste dentro del botón
	},
  }));

  function getSteps() {
	return ['Tipo de cultivo', 'Variedad', 'Datos del cultivo', 'Ubicación'];
  }

export const NewCropConfigPopup = ({openPopup, handleClosePopup, totalCrops, setTotalCrops, setOpenConfigNewCrop}) => {
	const classes = useStyles();
	const {usuario} = useContext(UserContext);
	const { addNewTabName, sendToCRcrop} = useContext(MultiCropContext)
	const [nameNewCrop, setNameNewCrop] = useState("");
	const [weekNewCrop, setWeekNewCrop] = useState("");
	const [dayNewCrop, setDayNewCrop] = useState("");
	const [placeNewCrop, setPlaceNewCrop] = useState("");
	const [newCrop, setNewCrop] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [cropCoordinates, setCropCoordinates] = useState(null);
	const steps = getSteps();
	const [activeStep, setActiveStep] = useState(0);
	const [selectedOption, setSelectedOption] = useState(null);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [phenologicalStageSelected, setPhenologicalStageSelected] = useState("")
	const [selectedVariant, setSelectedVariant] = useState(null);
	const [typeOfEnvironment, setTypeOfEnvironment] = useState("")
	const [nameOfManualCrop, setNameOfManualCrop] = useState("");
	const [nameOfManualVariant, setNameOfManualVariant] = useState("");
	const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const contentRef = useRef(null);

	const phenologicalOptions = ["Germinación/Brotación","Crecimiento vegetativo","Floración","Cuajado del fruto",
	"Engorde del fruto","Maduración del fruto","Cosecha","Senescencia"]

	const centralAlignment = {justifyContent:"center",alignItems:"center",textAlign:"center"}
	const initialAlignment = {justifyContent:"flex-start",alignItems:"flex-start",textAlign:"flex-start"}

	const handlePlaceChange = (event) => {
		const { value } = event.target;
		const newPlace = value === "indoor" ? "Interior" : "Exterior";
		setPlaceNewCrop(newPlace)
	}

	const handleCloseTheCropRegister = () => {
		setNameNewCrop("");
		setSelectedOption(null);
		setSelectedVariant(null);
		setPhenologicalStageSelected("");
		setSelectedDate(new Date());
		setWeekNewCrop("");
		setDayNewCrop("");
		setPlaceNewCrop("");
		setTypeOfEnvironment("");
		setNameOfManualCrop("");
		setActiveStep(0);
		handleClosePopup();


	}

	const handleCropSelected = (label) => {
		setSelectedOption(label);
	}
	const handleVariantSelected = (label) => {
		setSelectedVariant(label);
	}

	const handleNextStep = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBackStep = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStageDate = (date) => {
		const currentDate = new Date();
		setSelectedDate(date)
		const selected = new Date(date);
		// Cálculo de la diferencia en milisegundos
		const differenceInMillis = Math.abs(selected - currentDate);

		// Conversión a días totales
		const totalDays = Math.floor(differenceInMillis / (1000 * 60 * 60 * 24));

		// Cálculo de semanas y días
		const weeks = Math.floor(totalDays / 7);
		const days = totalDays % 7;

		setWeekNewCrop(weeks);
		setDayNewCrop(days === 0 ? 1 :days)
	}


	const addNewCrop = async() => {
		setIsLoading(true)
		const placeToSend = placeNewCrop === "Indoor" ? "Interior" : "Exterior";
		const indoorSystemToSend = placeNewCrop === "Indoor" ? typeOfEnvironment : "-";
		const soilTypeToSend = placeNewCrop === "Exterior" ? typeOfEnvironment : "-";
		const completeNameCropToSend = selectedOption !== "Otro" ? `${selectedOption} ${selectedVariant}` : `${nameOfManualCrop} ${nameOfManualVariant}`;
		const variantToSend = selectedOption !== "Otro" ? selectedVariant : nameOfManualVariant;
		const data = {
			username: usuario.username,
			action: "addCrop",
			nameOfCrop: nameNewCrop,
			typeOfCrop: completeNameCropToSend,
			weekOfCrop: weekNewCrop,
			dayOfCrop: dayNewCrop,
			placeOfCrop: placeToSend,
			phenStage: phenologicalStageSelected,
			dateOfStage: selectedDate,
			indoorSystem: indoorSystemToSend,
			soilType: soilTypeToSend,
			cropVariant: variantToSend,
			coordinates: cropCoordinates
		}
		console.log("Esto es data:",data)
		//const response = await saveData(nameNewCrop,weekNewCrop,dayNewCrop);
		const response = await sendToCRcrop(data);
		if(response === "allOK") {
			setTimeout(async () => {
					setOpenConfigNewCrop(false);
					//addNewTabName(nameNewCrop);
					setNameNewCrop("");
					setWeekNewCrop("");
					setDayNewCrop("");
					setNewCrop("");
					setPlaceNewCrop("");
					setIsLoading(false);
				// }
			}, 15000);
		}
		  
	}

	// Resetear el scroll al inicio cada vez que cambie el paso activo
	useEffect(() => {
		if (contentRef.current) {
		  contentRef.current.scrollTop = 0;
		}
	  }, [activeStep]);

  return (
	<div>
		<Dialog 
        // fullScreen={fullScreen}
        open={openPopup}
        onClose={handleCloseTheCropRegister}
        classes={{ paper: classes.dialogPaper }} 
        >
			<DialogTitle id="newCrop-title">{"Configuración de Nuevo Cultivo"}
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseTheCropRegister}>
				<CloseOutlined />
				</IconButton>
			</DialogTitle>

			<DialogContent ref={contentRef}>
				<div style={{marginTop: "5px", marginBottom: "10px"}}> 
					<Divider />
				</div>
				{isMobile ? (
					<div style={{justifyContent:"center",alignItems:"center",textAlign:"center"}}>
						<Typography variant="h6">
							{steps[activeStep]} {/* Muestra solo el paso actual */}
						</Typography>
                	</div>
				):(
				<Stepper alternativeLabel activeStep={activeStep}>
					{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
					))}
				</Stepper>
				)}
				{activeStep === 0 && (
					<>
					<div style={{marginTop: "5px", marginBottom: "10px"}}> 
						<Divider />
					</div>
					<div>
						<Grid container justifyContent='center' alignItems='center'>
							<label className={"mt-3"}>Nombre del Cultivo:</label>				
							<TextField 
							id="name-crop" 
							variant="outlined" 
							size="small"
							margin="dense"
							label="Nombre"
							value={nameNewCrop}
							onChange={(event) => {setNameNewCrop(event.target.value)}}
							className={classes.textField}
							/>
						</Grid>

						<div className={classes.container}>
							<h5>¿Qué estás cultivando?:</h5>
							<Grid container justifyContent="center">
								{cropOtions.map((option, index) => (
								<Grid item key={index}>
									<Button
									className={`${classes.button} ${selectedOption === option.label ? classes.selectedButton : ""}`}
									onClick={() => handleCropSelected(option.label)}
									>
									{option.label !== "Otro" && <img src={option.img} alt={option.label} className={classes.icon} />}
									<span className={classes.label}>{option.label}</span>
									</Button>
								</Grid>
								))}
								{selectedOption === "Otro" && (
									<Grid container justifyContent='center' alignItems='center'>
										<label className={"mt-3 mr-2"}>Cultivo de: </label>				
										<TextField 
										id="name-manual-crop" 
										variant="outlined" 
										size="small"
										margin="dense"
										// label=""
										value={nameOfManualCrop}
										onChange={(event) => {setNameOfManualCrop(event.target.value)}}
										className={classes.textField}
										/>
									</Grid>
								)}
							</Grid>
						</div>
					</div>
					</>
				)}

				{activeStep === 1 && (
					<>
						<div style={{marginTop: "10px", marginBottom: "10px"}}> 
							<Divider />
						</div>
						<Grid container justifyContent='center' alignItems='center'>
							<h5>¿Cual es la variedad de {selectedOption === "Otro" ? nameOfManualCrop : selectedOption}?</h5>
						</Grid>	
						{selectedOption !== "Otro" && 
						<Grid container justifyContent="center">
							{variantOptions[selectedOption]?.map((option, index) => (
							<Grid item key={index}>
								<Button
								className={`${classes.button} ${selectedVariant === option ? classes.selectedButton : ""}`}
								onClick={() => handleVariantSelected(option)}
								>
								<span className={classes.label}>{option}</span>
								</Button>
							</Grid>
							))}
						</Grid>
						}
						{selectedOption === "Otro" &&
							<Grid container justifyContent='center' alignItems='center'>				
								<TextField 
								id="name-manual-variant" 
								variant="outlined" 
								size="small"
								margin="dense"
								label="Nombre de la variante"
								value={nameOfManualVariant}
								onChange={(event) => {setNameOfManualVariant(event.target.value)}}
								className={classes.textField}
								/>
							</Grid>
						}
						
					</>
				)}

				{activeStep === 2 && (
					<>
					<div style={{marginTop: "10px", marginBottom: "10px"}}> 
						<Divider />
					</div>
					<Grid container justifyContent='center' alignItems='center'>
						<h5>Etapa del cultivo</h5>
					</Grid>
					<Grid container justifyContent='center'>
						<Grid item xs={12}>
							<label className={"mt-3"}>Etapa Fenológica:</label>
							<div>
							<FormControl variant="outlined" className={classes.formControl}>
								<Select
								labelId="phenologicalStage"
								id="pehonological-stage"
								value={phenologicalStageSelected}
								onChange={(event) => setPhenologicalStageSelected(event.target.value)}
								displayEmpty
								// label="Lugar"
								labelWidth={0}
								
								>
									{phenologicalOptions.map((stageOption,index) => (
										<MenuItem key={index} value={stageOption}>{stageOption}</MenuItem>
									))}
								
								</Select>
							</FormControl>
							</div>
						</Grid>
						
						<Grid item xs={12}>
							<div>
								<label className={"mt-3 mr-2"}>Fecha en que inicio la etapa de {phenologicalStageSelected}:</label>
							</div>
							<div style={{justifyContent:"center", alignItems:"center", textAlign:"center"}}>
							{/* <label className={"mt-3 mr-2"}>Fecha en que inicio la etapa de {phenologicalStageSelected}:</label> */}
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="dd/MM/yyyy"
								margin="normal"
								id="date-picker-inline"
								value={selectedDate}
								onChange={(date) => handleStageDate(date)}
								KeyboardButtonProps={{
								"aria-label": "change date",
								}}
								maxDate={new Date()} // Restringe las fechas futuras
							/>
							</MuiPickersUtilsProvider>
							</div>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={{...isMobile ? initialAlignment : centralAlignment,}}>
								<label className={"mt-3"}>Semana de {phenologicalStageSelected}: {weekNewCrop}</label>
							</div>
							
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={{...isMobile ? initialAlignment : centralAlignment}}>
								<label className={"mt-3"}>Día de {phenologicalStageSelected}: {dayNewCrop}</label>
							</div>
						</Grid>
					
					</Grid>
					<div style={{marginTop: "10px", marginBottom: "10px"}}> 
						<Divider />
					</div>
					<Grid container justifyContent='center' alignItems='center'>
						<h5>Datos del entorno</h5>
					</Grid>
					<Grid container justifyContent='flex-start'>
						<Grid item xs={12}>
							<label className={"mt-3"}>Entorno del cultivo:</label>
							<FormControl variant="outlined" className={classes.formControl}>
								{/* <InputLabel id="demo-simple-select-outlined-label">Lugar</InputLabel> */}
								<Select
								labelId="demo-simple-select-outlined-label"
								id="demo-simple-select-outlined"
								value={placeNewCrop}
								onChange={(event) => setPlaceNewCrop(event.target.value)}
								displayEmpty
								// label="Lugar"
								labelWidth={0}
								>
								<MenuItem value={""}>
									<em>Interior/Exterior</em>
								</MenuItem>
								<MenuItem value={"Indoor"}>Interior</MenuItem>
								<MenuItem value={"Exterior"}>Exterior</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							{placeNewCrop !== "" && (
								<>
								<label className={"mt-3"}>{placeNewCrop === "Exterior" ? "Tipo de suelo:": "Tipo de sistema:"}</label>
								<FormControl variant="outlined" className={classes.formControl}>
									{/* <InputLabel id="demo-simple-select-outlined-label">Lugar</InputLabel> */}
									<Select
									labelId="env-type"
									id="envType"
									value={typeOfEnvironment}
									onChange={(event) => setTypeOfEnvironment(event.target.value)}
									displayEmpty
									// label="Lugar"
									labelWidth={0}
									>
									{placeNewCrop === "Exterior" && 
										typeOfSoil?.map((soil,index) => (
										<MenuItem key={index} value={soil}>{soil}</MenuItem>
										))
									}
									{placeNewCrop === "Indoor" && 
										typeOfSystem?.map((system,index) => (
										<MenuItem key={index} value={system}>{system}</MenuItem>
										))
									}
									
									</Select>
								</FormControl>
								</>
							)}
						</Grid>
					</Grid>
						
					</>
				)}

				{activeStep === 3 && (
					<>
					<div style={{marginTop: "15px", marginBottom: "15px"}}> 
						<Divider />
					</div>

					<Grid container justifyContent='center' alignItems='center'>
						<Grid item xs={12} sm={12}>
							<div style={{ textAlign: 'center'}}>
								<label className={"mt-3"}>Ubicacion del Cultivo:</label>
							</div>
							
						</Grid>
						<Grid item xs={12} sm={12}>
							<MapForCrop setCurrentCoordinates={setCropCoordinates} initialCoordinates={null}/>
						</Grid>
					</Grid>
					<div style={{marginTop: "15px", marginBottom: "15px"}}> 
						<Divider />
					</div>

					<Grid container justifyContent='center' alignItems='center'>
						{isLoading ? 
						<CircularProgress color="primary" /> 
						:
						<Button 
						variant="contained" 
						color="primary" 
						onClick={addNewCrop}
						disabled={cropCoordinates === null}>
							Registrar Cultivo
						</Button>
						}
					</Grid>			
					</>
				)}
          	</DialogContent>
			<DialogActions>
				<Button variant="outlined"  color="primary" onClick={handleBackStep} disabled={activeStep === 0}>
					Atras
				</Button>
				{activeStep !== 3 && (
				<Button 
				variant="contained" 
				color="primary" 
				autoFocus 
				onClick={handleNextStep} 
				disabled={activeStep === 3 || (activeStep === 0 && (nameNewCrop === "" || selectedOption === null)) ||
					(activeStep === 0 && (selectedOption === "Otro" && nameOfManualCrop === ""))
					|| (activeStep === 1 && (selectedOption === "Otro" && nameOfManualVariant === ""))
					|| (activeStep === 1 && (selectedOption !== "Otro" && selectedVariant === null))
					|| (activeStep === 2 && (phenologicalStageSelected === "" || weekNewCrop === "" || dayNewCrop === ""))
					|| (activeStep === 2 && (placeNewCrop === "" || typeOfEnvironment === ""))
				}
				>
					Siguiente
				</Button>
				)}
			</DialogActions>

		</Dialog>
	</div>
  )
}
