import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Card, CardContent, CircularProgress, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { GoogleMap, DrawingManager, useLoadScript } from '@react-google-maps/api';
import { SelectCropField } from './SelectCropField';
import { LocationSearching } from '@material-ui/icons';
import { UserContext } from '../../../context/UserProvider';
import { FilteringIndexValues } from './FilteringIndexValues';
import { HistoricalWeatherData } from './HistoricalWeatherData';
import { FormOfDrawnPolygonName } from './FormOfDrawnPolygonName';

// Componentes de librerías que necesitaremos
const libraries = ['drawing', 'places', 'geometry'];
const useStyles = makeStyles((theme) => ({
	root: {
	  width: '100%',
	  padding: theme.spacing(2),
	  boxSizing: 'border-box',
	  boxShadow: "0px 0px 4px black", //offset-x | offset-y | blur-radius | color
	  [theme.breakpoints.down('xs')]: {
		width: '85vw', // Para pantallas pequeñas, ocupa más espacio
	  },
	},
  sideCard: {
	  width: '100%',
    height: '100%',
	  //padding: theme.spacing(1),
	  boxSizing: 'border-box',
	  boxShadow: "0px 0px 1px black", //offset-x | offset-y | blur-radius | color
	  [theme.breakpoints.down('xs')]: {
		width: '85vw', // Para pantallas pequeñas, ocupa más espacio
	  },
	},
	title: {
	  fontWeight: 'bold',
	},
	form: {
	  marginTop: theme.spacing(2),
	},
	button: {
	  marginTop: theme.spacing(2),
	},
}));

const containerStyle = {
  width: '100%',
  height: '50vh'
};

const center = {
  lat: 19.42847,
  lng: -99.12766
};


export const SatelliteAnalysis = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries,
  });
  const classes = useStyles();
  const { usuario } = useContext(UserContext);
  const [map, setMap] = useState(null); // Guardamos la referencia al mapa una vez cargado
  const [drawPolygon, setDrawPolygon] = useState(false)
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [drawingMode, setDrawingMode] = useState(null);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [overlay, setOverlay] = useState(null);
  const [dateOfImage, setDateOfImage] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageName, setImageName] = useState('');
  const [loadignAnalysis, setLoadignAnalysis] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [coordinatesOfDrawnPolygon, setCoordinatesOfDrawnPolygon] = useState({})
  const [currentPolygonCoordinates, setCurrentPolygonCoordinates] = useState([])
  const [indiceName, setIndiceName] = useState("NDVI");
  const [typeAddPolygon, setTypeAddPolygon] = useState("")
  const [openDrawingNameForm, setOpenDrawingNameForm] = useState(false)
  const [nameOfDrawnPolygon, setNameOfDrawnPolygon] = useState("")
  const [drawnPolygon, setDrawnPolygon] = useState(null);

  
  const onLoadMap = (mapInstance) => {
    setMap(mapInstance); // Guarda la referencia al mapa cuando se carga
    mapInstance.setMapTypeId('satellite'); // Mantener la vista satelital siempre
    // Desactivar el avatar de Street View y opciones de cambio de vista
    mapInstance.setOptions({
      streetViewControl: false,
      mapTypeControl: false,
    });
  };
  
  const toGeoJSON = (coords) => {
    const formattedCoordinates = coords.map(coord => [coord.lng, coord.lat,0]) // GeoJSON usa [lng, lat]
     // Verificamos si el primer y el último punto coinciden
    if (formattedCoordinates.length > 0) {
      const firstCoord = formattedCoordinates[0];
      const lastCoord = formattedCoordinates[formattedCoordinates.length - 1];
      
      // Si no coinciden, agregamos el primero al final para "cerrar" el polígono
      if (firstCoord[0] !== lastCoord[0] || firstCoord[1] !== lastCoord[1]) {
        formattedCoordinates.push([...firstCoord]); 
      }
    }

    return {
      geometry: {
        type: "Polygon",
        coordinates: [[...formattedCoordinates]]
      }
    };
  };

  const onPolygonComplete = (polygon) => {
    if (window.google && window.google.maps) {
      // Hacer que el polígono sea editable
      polygon.setEditable(true);

      
      const path = polygon.getPath();
      const area = window.google.maps.geometry.spherical.computeArea(path);
      console.log('Área del polígono:', area, 'm²');
      // Desactivar el modo de dibujo
      setDrawingMode(null);

      // Añadir listeners para manejar cambios en el polígono (opcional)
      window.google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        console.log('Vértice modificado o agregado');
      });
      window.google.maps.event.addListener(polygon.getPath(), 'remove_at', () => {
        console.log('Vértice eliminado');
      });

      const coordinates = path.getArray().map(coord => ({
        lat: coord.lat(),
        lng: coord.lng(),
      }));

      console.log('Coordenadas del polígono:', coordinates);
      const newCoordinates = toGeoJSON(coordinates)
      console.log("Esto es newCoordinates:",newCoordinates)
      setCoordinatesOfDrawnPolygon(newCoordinates)
      setDrawPolygon(true)
      setOpenDrawingNameForm(true)
      // Guarda la referencia en el estado
      setDrawnPolygon(polygon);
    }
  };

  const handlePastAnalysis = async () => {
    setLoadignAnalysis(true);
    try {
      const selectedFeature = geoJsonData.features.find(
        (feature) => feature.properties.name === selectedPolygon
      );
      const name = "S2B_tile_20241027_12RXP_0_Escalera";
      const dateOfIm = "2024-10-27"
      const dataJson = {
        username: usuario.username,
        nameOfImage: name
      }
      const dataToSend = JSON.stringify(dataJson);
      const response = await fetch('https://us-central1-gapy-c999c.cloudfunctions.net/Apoyo-Datos-Satelitales', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: dataToSend,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const jsonData = await response.json();
      const image_url = jsonData.image_url
      // Guardamos los datos en localStorage
      localStorage.setItem('satelliteAnalysis', JSON.stringify(jsonData));
      setDateOfImage(dateOfIm)
      setImageName(name)
      if (image_url) {
        // Crear una superposición de imagen
        const bounds = new window.google.maps.LatLngBounds();
        selectedFeature.geometry.coordinates[0].forEach(([lng, lat]) => {
          bounds.extend(new window.google.maps.LatLng(lat, lng));
        });
        if (overlay) {
          // Eliminar la imagen superpuesta anterior
          overlay.setMap(null);
        }

        const newOverlay = new window.google.maps.GroundOverlay(image_url, bounds, {
          map: map,
          opacity: 1, // Aumentar opacidad de la imagen
        });
        setOverlay(newOverlay);
        setImageLoaded(true); // Marcar la imagen como cargada
        setLoadignAnalysis(false)
      }

    } catch (error) {
      console.error("Error en buscar y cargar la iamgen:",error)
    }
  }


  const handleSendAnalysis = async() => {
    // Llamar a la API de EOS para obtener el análisis de NDVI
    setLoadignAnalysis(true);
    try {
      //let coordinatesInGeojson = {}
      let selectedFeature = {}
      if(typeAddPolygon === "file") {
        selectedFeature = geoJsonData.features.find(
          (feature) => feature.properties.name === selectedPolygon
        );
        
      } else if(typeAddPolygon === "drawing") {
        selectedFeature = coordinatesOfDrawnPolygon
      }
      
      // if(geoJsonData){
      //   const selectedFeature = geoJsonData.features.find(
      //     (feature) => feature.properties.name === selectedPolygon
      //   );
      //   if(selectedFeature !== undefined) {
      //     coordinatesInGeojson = selectedFeature.geometry
      //   }
      // } else {
      //   coordinatesInGeojson = coordinatesOfDrawnPolygon
      // }
      // console.log("Esto es selectedFeature:",selectedFeature.geometry);
      const dataJson = {
        geojson: selectedFeature.geometry,
        polygonName: typeAddPolygon === "file" ? selectedPolygon : nameOfDrawnPolygon,
        username: usuario.username,
        indice: indiceName
      }
      // const coordinatesToSend = JSON.stringify(coordinates)
      // setCoordinatesSelected(coordinatesToSend)
      const dataToSend = JSON.stringify(dataJson);
      console.log("Esto es dataToSend:",dataToSend)
      const response = await fetch('https://us-central1-gapy-c999c.cloudfunctions.net/getSatelliteData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: dataToSend,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonData = await response.json();
      const image_url = jsonData.image_url
      // Guardamos los datos en localStorage
      localStorage.setItem('satelliteAnalysis', JSON.stringify(jsonData));
      setDateOfImage(jsonData.date)
      setImageName(jsonData.name)
      if (image_url) {
        // Crear una superposición de imagen
        const bounds = new window.google.maps.LatLngBounds();
        selectedFeature.geometry.coordinates[0].forEach(([lng, lat]) => {
          bounds.extend(new window.google.maps.LatLng(lat, lng));
        });
        if (overlay) {
          // Eliminar la imagen superpuesta anterior
          overlay.setMap(null);
        }

        const newOverlay = new window.google.maps.GroundOverlay(image_url, bounds, {
          map: map,
          opacity: 1, // Aumentar opacidad de la imagen
        });
        setOverlay(newOverlay);
        setImageLoaded(true); // Marcar la imagen como cargada
        setLoadignAnalysis(false)
      }

    } catch (error) {
      console.error('Error al hacer la solicitud a la API de EOS:', error);
    }
  }

  const handleImageFiltering = async (dataForFilter) => {
    setLoadingFilter(true);
    try {
      const selectedFeature = geoJsonData.features.find(
        (feature) => feature.properties.name === selectedPolygon
      );	
      // Ejemplo:
      const response = await fetch('https://us-central1-gapy-c999c.cloudfunctions.net/processSatelliteImage', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: dataForFilter,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const jsonDataFilter = await response.json();
      const filteredImage_url = jsonDataFilter.image_url
      if (filteredImage_url) {
        // Crear una superposición de imagen
        const bounds = new window.google.maps.LatLngBounds();
        selectedFeature.geometry.coordinates[0].forEach(([lng, lat]) => {
          bounds.extend(new window.google.maps.LatLng(lat, lng));
        });
        if (overlay) {
          // Eliminar la imagen superpuesta anterior
          overlay.setMap(null);
        }

        const newOverlay = new window.google.maps.GroundOverlay(filteredImage_url, bounds, {
          map: map,
          opacity: 1, // Aumentar opacidad de la imagen
        });
        setOverlay(newOverlay);
        setLoadingFilter(false);
       
      }
    } catch (error) {
      console.error("Error en la peticion de filtrado:",error)
    }
  }

  const handleContrast = async () => {
    try {
      const selectedFeature = geoJsonData.features.find(
        (feature) => feature.properties.name === selectedPolygon
      );
      const dataJson = {
        imageName: imageName,
        username: usuario.username,
        indice: indiceName
      }
      const dataToSend = JSON.stringify(dataJson);
      console.log("Esto es dataToSend:",dataToSend)
      const response = await fetch('https://us-central1-gapy-c999c.cloudfunctions.net/contrastInSatelliteImagery', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: dataToSend,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonData = await response.json();
      const image_url = jsonData.image_url;
      if (image_url) {
        // Crear una superposición de imagen
        const bounds = new window.google.maps.LatLngBounds();
        selectedFeature.geometry.coordinates[0].forEach(([lng, lat]) => {
          bounds.extend(new window.google.maps.LatLng(lat, lng));
        });
        if (overlay) {
          // Eliminar la imagen superpuesta anterior
          overlay.setMap(null);
        }

        const newOverlay = new window.google.maps.GroundOverlay(image_url, bounds, {
          map: map,
          opacity: 1, // Aumentar opacidad de la imagen
        });
        setOverlay(newOverlay);
        setImageLoaded(true); // Marcar la imagen como cargada
        setLoadignAnalysis(false)
      }

    } catch (error) {
      console.error("Error en hacer el contraste:",error)
    }
  }

  const handleNoFilter = () => {
    try {
      // Intentar obtener datos desde localStorage
    const originalImage = localStorage.getItem('satelliteAnalysis');
    if (originalImage) {
      const jsonData = JSON.parse(originalImage);
      const image_url = jsonData.image_url
      if(image_url) {
        const selectedFeature = geoJsonData.features.find(
          (feature) => feature.properties.name === selectedPolygon
        );
        // Crear una superposición de imagen
        const bounds = new window.google.maps.LatLngBounds();
        selectedFeature.geometry.coordinates[0].forEach(([lng, lat]) => {
          bounds.extend(new window.google.maps.LatLng(lat, lng));
        });
        if (overlay) {
          // Eliminar la imagen superpuesta anterior
          overlay.setMap(null);
        }

        const newOverlay = new window.google.maps.GroundOverlay(image_url, bounds, {
          map: map,
          opacity: 1, // Aumentar opacidad de la imagen
        });
        setOverlay(newOverlay);
      }
    }
    } catch (error) {
      console.error("Error en sobreponer imagen sin filtro:",error)
    }
  }

  const processPoints = (geometry, callback, thisArg) => {
    if (geometry instanceof window.google.maps.LatLng) {
      callback.call(thisArg, geometry);
    } else if (geometry instanceof window.google.maps.Data.Point) {
      callback.call(thisArg, geometry.get());
    } else {
      geometry.getArray().forEach((g) => {
        processPoints(g, callback, thisArg);
      });
    }
  };

  useEffect(() => {
    if(geoJsonData !== null) {
      const fileDataArray = geoJsonData.features;
      const arrayOfNames = [];
      if (map) {
        map.data.addGeoJson(geoJsonData);

        // Ajustar los límites del mapa
        const bounds = new window.google.maps.LatLngBounds();
        map.data.forEach(function (feature) {
          processPoints(feature.getGeometry(), bounds.extend, bounds);
        });
        map.fitBounds(bounds);
      }
      fileDataArray.forEach((item) => {
        if (item.geometry.type === "Polygon") {
          arrayOfNames.push(item.properties.name)
        }
      });
      
    }
  }, [geoJsonData,map])

  useEffect(() => {
    if(selectedPolygon) {
      console.log("Esto es geoJsonData:",geoJsonData)
      const selectedFeature = geoJsonData.features.find(
        (feature) => feature.properties.name === selectedPolygon
      );
      console.log("Esto es selectedFeature:",selectedFeature)
      
      if (selectedFeature) {
        // Obtener las coordenadas del centro del polígono
        const bounds = new window.google.maps.LatLngBounds();
        selectedFeature.geometry.coordinates[0].forEach(([lng, lat]) => {
          bounds.extend({ lat, lng });
        });
        map.fitBounds(bounds);
        setCurrentPolygonCoordinates(selectedFeature.geometry)
        if (overlay) {
          // Eliminar la imagen superpuesta anterior
          overlay.setMap(null);
        }
      }
    }
  }, [selectedPolygon,geoJsonData,overlay])


  if (loadError) return <div>Error cargando los mapas.</div>;
  if (!isLoaded) return <div>Cargando mapas...</div>;

  return (
	<div>
    <Card className={classes.root}>
      <CardContent>
				<Typography variant="h6" className={classes.title}>
					Monitoreo de cultivo en exterior
				</Typography>

				<div style={{ marginBottom: "15px", marginTop: "10px"}}>
					<Divider />
				</div>
        <div>
          <Grid container direction="row" justifyContent='center' spacing={1}>
            <Grid item xs={12} sm={9}>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                  onLoad={onLoadMap} // Se ejecuta cuando el mapa se ha cargado
                  options={{
                    gestureHandling: 'greedy',
                  }}
                >
                  {((map && drawPolygon) && typeAddPolygon === "drawing") && (
                    <DrawingManager
                    onPolygonComplete={onPolygonComplete}
                    options={{
                      drawingControl: false,
                      drawingMode: drawingMode,
                      polygonOptions: {
                        strokeColor: 'green',
                        strokeWeight: 2,
                        fillOpacity: 0,      // <--- fondo transparente
                        clickable: true,
                        editable: true,
                        zIndex: 1,
                      },
                    }}
                    />
                  )}
                </GoogleMap>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Card className={classes.sideCard}>
                <CardContent>
                  <SelectCropField 
                  setGeoJsonData={setGeoJsonData} 
                  setDrawPolygon={setDrawPolygon} 
                  setDrawingMode={setDrawingMode}
                  setSelectedPolygon={setSelectedPolygon}
                  setImageLoaded={setImageLoaded}
                  setDateOfImage={setDateOfImage}
                  setIndiceName={setIndiceName}
                  setTypeAddPolygon={setTypeAddPolygon}
                  typeAddPolygon={typeAddPolygon}
                  drawnPolygon={drawnPolygon}
                  setDrawnPolygon={setDrawnPolygon}
                  />
                  
                  {(selectedPolygon || drawPolygon) && (
                    <div style={{ textAlign: 'center' }}>
                      {loadignAnalysis ? 
                      <CircularProgress />
                      :
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<LocationSearching />}
                        // onClick={handleSendAnalysis}
                        onClick={handleSendAnalysis}
                        disabled={loadignAnalysis}
                      >
                        Analizar campo
                      </Button>
                      }
                    </div>
                  )}
                  {dateOfImage !== '' && (
                    <div style={{ marginBottom: "15px", marginTop: "15px"}}>
                    <Typography>
                      Fecha del análisis: {dateOfImage}
                    </Typography>
                    </div>
                  )}
                  {(selectedPolygon && imageLoaded && indiceName !== "NDMI") && (
                    <FilteringIndexValues 
                    username={usuario.username} 
                    imageName={imageName} 
                    handleImageFiltering={handleImageFiltering}
                    handleNoFilter={handleNoFilter}
                    loadingFilter={loadingFilter}
                    setLoadingFilter={setLoadingFilter}
                    />
                  )}
                  {(selectedPolygon && imageLoaded && indiceName === "NDMI") && (
                    <Button variant="contained" color="primary" size='small' onClick={handleContrast}>
                      Aplicar Contraste
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
        
          </Grid>
        </div>
        <HistoricalWeatherData coordinates={currentPolygonCoordinates} polygonName={selectedPolygon} />
        <FormOfDrawnPolygonName openForm={openDrawingNameForm} setOpenForm={setOpenDrawingNameForm} polygonName={setNameOfDrawnPolygon} />
      </CardContent>
    </Card>
  </div>
  )
}
