import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core'


export const FormOfDrawnPolygonName = ({openForm,setOpenForm,polygonName}) => {
	const [nameOfPolygon, setNameOfPolygon] = useState("")

	const handleClose = () => {
		setOpenForm(false)
	}

	const handleSaveName = () => {
		polygonName(nameOfPolygon)
		handleClose()
	}
  return (
	<div>
		<Dialog open={openForm} onClose={handleClose} aria-labelledby="form-dialog-title">
        	<DialogTitle id="form-dialog-title">Nombre del Poligono</DialogTitle>
			<DialogContent>
			<DialogContentText>
				Ingresa un nombre para el poligono que dibujaste
			</DialogContentText>
			<TextField
				autoFocus
				margin="dense"
				id="name"
				label="Nombre"
				value={nameOfPolygon}
				onChange={(event) => setNameOfPolygon(event.target.value)}
				fullWidth
			/>
			</DialogContent>
			<DialogActions>
			{/* <Button onClick={handleClose} color="primary">
				Cancel
			</Button> */}
			<Button variant='contained' onClick={handleSaveName} color="primary">
				Aceptar
			</Button>
			</DialogActions>
      </Dialog>
	</div>
  )
}
