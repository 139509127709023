
const mangoIcon = '/mango-icon.svg';
const raspberryIcon = '/raspberry-icon.svg';
const tomatoIcon = '/tomato-icon.svg';
const lettuceIcon = '/lettuce-icon.svg';
const cucumberIcon = '/cucumber-icon.svg';

const cropOtions = [
	{label: "Frambuesa",img:raspberryIcon },
	{label: "Lechuga",img:lettuceIcon },
	{label: "Mango",img:mangoIcon },
	{label: "Pepino",img:cucumberIcon },
	{label: "Tomate",img:tomatoIcon },
	{label: "Otro"}
];

const variantOptions = {
	Mango: ["Ataulfo","Haden","Kent","Keitt","Manila","Palmer","Tommy Atkins"],
	Frambuesa:["Adelita","Autumn Bliss","Caroline","Cascade","Enrosadira","Fallgold","Glen Ample","Glen Dee","Glen Lyon","Heritage","Kwanza","Meeker","Tadmor","Tulameen","Versailles"],
	Tomate: ["Beefsteak-Big Boy", "Beefsteak-Brandywine","Cherry amarillo","Cherry rojo","Corazón de buey","Heirloom-Cherokee Purple","Heirloom-Green Zebra","Kumato","Pera amarillo","Pera rojo","Roma San Marzano","Roma VF"],
	Pepino: ["Armenio","Cornichón (para encurtir)","Español","Inglés (larga vida)","Lemon","Persa","Sin espinas (burpless)","Slicer (americano)"],
	Lechuga: ["Batavia","Crespa verde","Crespa roja","Frisée (escarola)","Iceberg","Lollo rosso","Mantequilla o Butterhead","Oakleaf roja","Oakleaf verde","Radicchio","Romana roja","Romana verde"]
}

const typeOfSoil = ["Arcilloso","Arenoso","Aluvial","Franco","Limoso","Pedregoso","Salino","Turboso"]
const typeOfSystem = ["Aeroponía","DWC (Deep Water Culture)","Ebb and Flow (Flujo y Reflujo)","NFT (Nutrient Film Technique)",
	"Sistema Acuapónico","Sistema de Goteo","Sistema Vertical","Técnica de Sustrato",
	"Wick System (Sistema de Mecha)","Otro (especifique)"
]

const phenologicalOptions = ["Germinación/Brotación","Crecimiento vegetativo","Floración","Cuajado del fruto",
	"Engorde del fruto","Maduración del fruto","Cosecha","Senescencia"]

export {
	cropOtions,
	variantOptions,
	typeOfSoil,
	typeOfSystem,
	phenologicalOptions
}