import React, { useContext, useState } from 'react';
import {
  Grid,
  Divider,
  Typography,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { UserContext } from '../../../context/UserProvider';
import { ConsultJapy } from './ConsultJapy';
import { HistoricalChats } from './HistoricalChats';
import { MultiCropContext } from '../../../context/MultiCropContext/MultiCropContext';
import { SelectSensors } from './SelectSensors';


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    position: 'relative',
		minWidth: "35%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('sm')]: {
			width: "100%", // Ocupa todo el ancho en pantallas pequeñas
		},
  },
  title: {
    marginTop: theme.spacing(2),
    //marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const theme = createTheme({
  typography: {
    subtitle1: {
      fontSize: 18,
    },
  },
});


const ChatBotForm = () => {
  const classes = useStyles();
  const {usuario}=useContext(UserContext);
  const {namesOfCrops,crops,weeksOfCrops,daysOfCrops,placeOfCrops,currentCoordinates,
    variantsOfCrop,phenoStages,datesOfStages,indoorSystems,soilTypes,
  } = useContext(MultiCropContext)
  const [loadingChat, setLoadingChat] = useState(false);
  const [synchronized, setSynchronized] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [cropSelected, setCropSelected] = useState(0)
  const weekAndDay = `Semana ${weeksOfCrops[cropSelected]} y Día ${daysOfCrops[cropSelected]}`;
  const envCrop = placeOfCrops[cropSelected] === "Exterior" ? soilTypes[cropSelected] : indoorSystems[cropSelected]

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeCrop = (event) => {
    setCropSelected(event.target.value)
  }

  return (
    <>
    <div>
      <Tabs 
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}            
      >
        <Tab label="Consulta a Japy" />
        <Tab label="Historial de chats" />        
      </Tabs>
    </div>

    <div>
      <Grid 
      container  
      justifyContent="center"
      alignItems="center" >
        <Typography variant="h6" className={classes.title}>
          Datos del Cultivo
        </Typography>
      </Grid>
    </div>

    <div>
        <Grid container alignItems='center' justifyContent='center'>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="select-crop">Selecciona Cultivo</InputLabel>
          <Select
            labelId="Select-Crop"
            id="Select-Crop"
            value={cropSelected}
            onChange={handleChangeCrop}
            label="Selecciona Cultivo"
            labelWidth={0}
            disabled={loadingChat}
          >
            {namesOfCrops.map((item,index) => (
              <MenuItem value={index} key={index}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>
      </div>

      <Grid container direction='column' justifyContent="center" alignItems="center">
        <ThemeProvider theme={theme}>
          <Typography variant="subtitle1">Tipo de cutlivo: {crops[cropSelected]} {variantsOfCrop[cropSelected]}</Typography>
          <Typography variant="subtitle1">Etapa Fenológica: {phenoStages[cropSelected]}</Typography>
          <Typography variant="subtitle1">Semana: {weeksOfCrops[cropSelected]}, Día: {daysOfCrops[cropSelected]}</Typography>
          <Typography variant="subtitle1">Entorno de cultivo: {placeOfCrops[cropSelected]}</Typography>
          <Typography variant="subtitle1">{placeOfCrops[cropSelected] === "Exterior" ? "Tipo de suelo:" : "Tipo de sistema:"} {envCrop}</Typography>
        </ThemeProvider>
      </Grid>


    <div hidden={tabValue !== 0}>
      {/* <Grid 
      container  
      justifyContent="center"
      alignItems="center" >
        <Typography variant="h6" className={classes.title}>
          Datos del Cultivo
        </Typography>
      </Grid>

      <div>
        <Grid container alignItems='center' justifyContent='center'>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="select-crop">Selecciona Cultivo</InputLabel>
          <Select
            labelId="Select-Crop"
            id="Select-Crop"
            value={cropSelected}
            onChange={handleChangeCrop}
            label="Selecciona Cultivo"
            labelWidth={0}
            disabled={loadingChat}
          >
            {namesOfCrops.map((item,index) => (
              <MenuItem value={index} key={index}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>
      </div>

      <Grid container direction='column' justifyContent="center" alignItems="center">
        <ThemeProvider theme={theme}>
        <Typography variant="subtitle1">Tipo de cutlivo: {crops[cropSelected]}</Typography>
        <Typography variant="subtitle1">Semana: {weeksOfCrops[cropSelected]}, Día: {daysOfCrops[cropSelected]}</Typography>
        <Typography variant="subtitle1">Lugar de cultivo: {placeOfCrops[cropSelected]}</Typography>
        </ThemeProvider>
      </Grid> */}

      <div style={{ marginTop: '15px' }}>
        <Divider className={classes.divider} />
      </div>
          

      <Grid container justifyContent="center" alignItems="center">
        <Typography variant="h6" className={classes.title}>
          Parámetros
        </Typography>
      </Grid>

      <SelectSensors 
      indexOfCrop={cropSelected} 
      username={usuario.username} 
      confirmSync={() => setSynchronized(true)}
      loadingChat={loadingChat}
      />

      <div style={{ marginTop: '15px' }}>
        <Divider className={classes.divider} />
      </div>
        
      {/* <Tabs 
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}            
      >
        <Tab label="Consulta a Japy" />
        <Tab label="Historial de chats" />        
      </Tabs> */}

      {/* <div hidden={tabValue !== 0}> */}
        <ConsultJapy 
        username={usuario.username}
        cropType={`${crops[cropSelected]} ${variantsOfCrop[cropSelected]}`}
        weekStage={weekAndDay}
        growingSite={placeOfCrops[cropSelected]}
        synchronized={synchronized}
        setSynchronized={setSynchronized}
        actualCrop={namesOfCrops[cropSelected]}
        loadingChat={loadingChat}
        setLoadingChat={setLoadingChat}
        cropCoordinates={currentCoordinates[cropSelected]}
        phenoStage={phenoStages[cropSelected]}
        envCrop={envCrop}
        />
    </div>

    <div hidden={tabValue !== 1}>
      <HistoricalChats username={usuario.username} cropSelected={cropSelected} />
    </div>
      
    </>
  );
}

export default ChatBotForm;