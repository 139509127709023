import React, { useContext, useEffect, useState } from 'react'
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons';
import { MultiCropContext } from '../../../../context/MultiCropContext/MultiCropContext';
import { UserContext } from '../../../../context/UserProvider';
import { db } from '../../../../config/firebase';
import { MapForCrop } from '../../Maps/MapForCrop';
import { cropOtions, phenologicalOptions, typeOfSoil, typeOfSystem, variantOptions } from '../../../../constants/CropsAndVariantOptions';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from "date-fns/locale";


const useStyles = makeStyles((theme) => ({
	dialogPaper: {
	  minWidth: '50vw', // Asegura un mínimo de ancho para el diálogo
	  maxWidth: '50vw', // Un máximo para evitar que sea demasiado grande
	  [theme.breakpoints.down('xs')]: {
		minWidth: '80vw', // Para pantallas pequeñas, ocupa más espacio
	  },
	},
	closeButton: {
	  position: 'absolute',
	  right: theme.spacing(1),
	  top: theme.spacing(1),
	  color: theme.palette.grey[500],
	},
	root: {
		'& .MuiTextField-root': {
		  margin: theme.spacing(1),
		  
		},
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "30ch",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
	},
	formControl: {
		margin: theme.spacing(1),
		position: 'relative',
		width: "90%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('sm')]: {
			width: "100%", // Ocupa todo el ancho en pantallas pequeñas
		},
	},
	button: {
		display: 'block',
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2)
	},
}));

export const EditCropConfig = ({openPopup, handleClosePopup}) => {
	const classes = useStyles();
	const { usuario } = useContext(UserContext)
	const { namesOfCrops, tabValue,weeksOfCrops,daysOfCrops,idOfCrops,placeOfCrops,crops,currentCoordinates,variantsOfCrop,
		phenoStages,datesOfStages,indoorSystems,soilTypes,setVariantsOfCrop,setPhenoStages,setDatesOfStages,setIndoorSystems,setSoilTypes,
		setWeeksOfCrops,setDaysOfCrops,setNamesOfCrops,sendToCRcrop,setTabValue,setCrops,setPlaceOfCrops,setCurrentCoordinates
	} = useContext(MultiCropContext);
	const [namesEdited, setNamesEdited] = useState([])
	const [weeksEdited, setWeeksEdited] = useState([])
	const [daysEdited, setDaysEdited] = useState([])
	const [cropsEdited, setCropsEdited] = useState([])
	const [placesEdited, setPlacesEdited] = useState([])
	const [isLoadingDelete, setIsLoadingDelete] = useState(false)
	const [isLoadingEdit, setIsLoadingEdit] = useState(false)
	const [coordinatesEdited, setCoordinatesEdited] = useState([]);
	const [variantsEdited, setVariantsEdited] = useState([])
	const [phenoStagesEdited, setPhenoStagesEdited] = useState([])
	const [datesEdited, setDatesEdited] = useState([])
	const [indoorSystemsEdited, setIndoorSystemsEdited] = useState([])
	const [soilTypesEdited, setSoilTypesEdited] = useState([])

	const handleNameChange = (event) => {
		const newName = event.target.value;
		const newArrayNames = [...namesEdited]
		newArrayNames[tabValue] = newName
		setNamesEdited([...newArrayNames])
	}

	const handleCropChange = (event) => {
		const newCrop = event.target.value;
		const newArrayCrops = [...cropsEdited]
		newArrayCrops[tabValue] = newCrop
		setCropsEdited([...newArrayCrops])
	}

	const handlePlaceChange = (event) => {
		const newPlace = event.target.value;
		const newArrayPlaces = [...placesEdited]
		newArrayPlaces[tabValue] = newPlace
		setPlacesEdited([...newArrayPlaces])
	}

	const handleVariantChange = (event) => {
		const newVariant = event.target.value;
		const newArrayVariants = [...variantsEdited]
		newArrayVariants[tabValue] = newVariant
		setVariantsEdited([...newArrayVariants])
	}

	const handlePhenoStageChange = (event) => {
		const newPhenoStage = event.target.value;
		const newArrayPhenoStages = [...phenoStagesEdited]
		newArrayPhenoStages[tabValue] = newPhenoStage
		setPhenoStagesEdited([...newArrayPhenoStages])
	}

	const handleSoilSystemChange = (event) => {
		const newEnv = event.target.value;
		const newArrayOfIndoorSystems = [...indoorSystemsEdited];
		const newArrayOfSoilTypes = [...soilTypesEdited]
		if(placesEdited[tabValue] === "Exterior") {
			newArrayOfSoilTypes[tabValue] = newEnv;
			newArrayOfIndoorSystems[tabValue] = "-"
		} else {
			newArrayOfIndoorSystems[tabValue] = newEnv;
			newArrayOfSoilTypes[tabValue] = "-"
		}
		setSoilTypesEdited([...newArrayOfSoilTypes])
		setIndoorSystemsEdited([...newArrayOfIndoorSystems])
	}

	const handleDeleteCrop = async () => {
		setIsLoadingDelete(true)
		const data = {
			username: usuario.username,
			action: "deleteCrop",
			index: tabValue
		}
		const response = await sendToCRcrop(data)
		if(response === "allOK") {
			const arrayOfNames = [...namesOfCrops];
			arrayOfNames.splice(tabValue,1)
			setNamesOfCrops([...arrayOfNames])
			setTabValue(0)
			handleClosePopup()
		}
	}

	const handleNewCoordinates = (newCoordinates) => {
		const arrayOfCoordinates = [...coordinatesEdited];
		arrayOfCoordinates[tabValue] = newCoordinates
		setCoordinatesEdited([...arrayOfCoordinates])

	}

	const handleStageDate = (date) => {
		const currentDate = new Date();
		const newArrayDates = [...datesEdited]
		const newWeeksArray = [...weeksEdited]
		const newArrayDays = [...daysEdited]
		const selectedDate = new Date(date);
		newArrayDates[tabValue] = selectedDate.toISOString();
		setDatesEdited([...newArrayDates])
		
		// Cálculo de la diferencia en milisegundos
		const differenceInMillis = Math.abs(selectedDate - currentDate);

		// Conversión a días totales
		const totalDays = Math.floor(differenceInMillis / (1000 * 60 * 60 * 24));

		// Cálculo de semanas y días
		const weeks = Math.floor(totalDays / 7);
		const days = totalDays % 7;

		newWeeksArray[tabValue] = weeks;
		newArrayDays[tabValue] = days === 0 ? 1 :days

		setWeeksEdited([...newWeeksArray]);
		setDaysEdited([...newArrayDays]);
	}

	const saveChanges = async () => {
		try {
			const addr = usuario.username + "/multiCrop/" + idOfCrops[tabValue];
			const docRef = db.collection(addr).doc('data');
			const dataToSave = {
				cropName: namesEdited[tabValue],
				cultivationOf: cropsEdited[tabValue],
				growingDay: daysEdited[tabValue],
				growingWeek: weeksEdited[tabValue],
				place: placesEdited[tabValue],
				cropVariant: variantsEdited[tabValue],
				phenologicalStage: phenoStagesEdited[tabValue],
				dateOfStage: datesEdited[tabValue],
				indoorSystem: indoorSystemsEdited[tabValue],
				soilType: soilTypesEdited[tabValue],
				cropCoordinates: coordinatesEdited[tabValue]
			}
			await docRef.set({...dataToSave})
			setNamesOfCrops([...namesEdited])
			setCrops([...cropsEdited])
			setWeeksOfCrops([...weeksEdited])
			setDaysOfCrops([...daysEdited])
			setPlaceOfCrops([...placesEdited])
			setVariantsOfCrop([...variantsEdited])
			setPhenoStages([...phenoStagesEdited])
			setDatesOfStages([...datesEdited])
			setIndoorSystems([...indoorSystemsEdited])
			setSoilTypes([...soilTypesEdited])
			setCurrentCoordinates([...coordinatesEdited])
			handleClosePopup()

		} catch (error) {
			console.error("Error en editar datos de cultivo:",error)
		}
	}

	//Aqui se cargan los datos actuales del multicultivo
	useEffect(() => {
		setNamesEdited([...namesOfCrops])
		setCropsEdited([...crops])
		setWeeksEdited([...weeksOfCrops])
		setDaysEdited([...daysOfCrops])
		setPlacesEdited([...placeOfCrops])
		setVariantsEdited([...variantsOfCrop])
		setPhenoStagesEdited([...phenoStages])
		setDatesEdited([...datesOfStages])
		setIndoorSystemsEdited([...indoorSystems])
		setSoilTypesEdited([...soilTypes])
		setCoordinatesEdited([...currentCoordinates])
	}, [namesOfCrops,daysOfCrops,weeksOfCrops,placeOfCrops,crops,currentCoordinates,variantsOfCrop,phenoStages,indoorSystems,datesOfStages,soilTypes])

	//Aqui se pasan los nuevos datos editados una vez que el popup se cierra
	useEffect(() => {
		if(!openPopup) {
			setNamesEdited([...namesOfCrops])
			setCropsEdited([...crops])
			setWeeksEdited([...weeksOfCrops])
			setDaysEdited([...daysOfCrops])
			setPlacesEdited([...placeOfCrops])
			setIsLoadingDelete(false)
			setCoordinatesEdited([...currentCoordinates])
		}
	}, [openPopup])
	
  return (
	<div>
		<Dialog
		open={openPopup}
        onClose={handleClosePopup}
        classes={{ paper: classes.dialogPaper }} 
		>
			<DialogTitle id="Edita-cultivo">
				{"Editar Cultivo"}
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleClosePopup}>
					<CloseOutlined />
				</IconButton>

			</DialogTitle>

			<DialogContent>
				<div style={{marginTop: "5px", marginBottom: "10px"}}> 
					<Divider />
				</div>
				<Grid container justifyContent='center' alignItems='center'>
					<TextField 
					id="name-crop" 
					variant="outlined" 
					size="small"
					margin="dense"
					label="Nombre del cultivo"
					//defaultValue={namesOfCrops[tabValue]}
					value={namesEdited[tabValue]}
					onChange={handleNameChange}
					className={classes.textField}
					/>
				</Grid>
				<div style={{marginTop: "5px", marginBottom: "10px"}}> 
					<Divider />
				</div>
				<Grid container justifyContent='center' alignItems='center'>
					
					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" className={classes.formControl}>
							<InputLabel id="type-of-crop">Cultivo</InputLabel>
							<Select
							labelId="crop-id"
							id="typeCrop"
							value={cropsEdited[tabValue]}
							onChange={handleCropChange}
							label="Cultivo"
							labelWidth={1}
							size="small"
							>
								{cropOtions.map((option,index) => (
									<MenuItem key={index} value={option.label}>{option.label}</MenuItem>
								))}
								
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" className={classes.formControl}>
							<InputLabel id="type-of-variant">Variante</InputLabel>
							<Select
							labelId="crop-variant"
							id="typeVaraintCrop"
							value={variantsEdited[tabValue]}
							onChange={handleVariantChange}
							label="Variante"
							labelWidth={1}
							size="small"
							>
								{variantOptions[cropsEdited[tabValue]]?.map((option,index) => (
									<MenuItem key={index} value={option}>{option}</MenuItem>
								))}
								
							</Select>
						</FormControl>
					</Grid>

					
					<Grid item xs={12} sm={6}>
						
						<FormControl variant="outlined" className={classes.formControl}>
							<InputLabel id="type-of-stage">Etapa Fenológica</InputLabel>
							<Select
							labelId="pheno-stage"
							id="phenoStage"
							value={phenoStagesEdited[tabValue]}
							onChange={handlePhenoStageChange}
							label="Etapa Fenologica"
							labelWidth={1}
							size="small"
							>
								{phenologicalOptions.map((option,index) => (
									<MenuItem key={index} value={option}>{option}</MenuItem>
								))}
								
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6}>
						<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="dd/MM/yyyy"
							margin="normal"
							id="dataOfStage"
							label="Inicio de la etapa"
							value={new Date(datesEdited[tabValue])}
							onChange={(date) => handleStageDate(date)}
							KeyboardButtonProps={{
							"aria-label": "change date",
							}}
							maxDate={new Date()} // Restringe las fechas futuras
						/>
						</MuiPickersUtilsProvider>
					</Grid>

					
					
				</Grid>
				<Grid container justifyContent='center' alignItems='center'>
					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" className={classes.formControl}>
							<InputLabel id="demo-simple-select-outlined-label">Entorno de cultivo</InputLabel>
							<Select
							labelId="enviro-crop"
							id="envCrop"
							value={placesEdited[tabValue]}
							onChange={handlePlaceChange}
							label="Entorno de cultivo"
							labelWidth={1}
							size="small"
							>
								<MenuItem value={"Interior"}>Interior</MenuItem>
								<MenuItem value={"Exterior"}>Exterior</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" className={classes.formControl}>
							<InputLabel id="demo-simple-select-outlined-label">{placesEdited[tabValue] === "Exterior" ? "Tipo de suelo":"Tipo de Sistema"}</InputLabel>
							<Select
							labelId="typeEnv-crop"
							id="typeEnv"
							value={placesEdited[tabValue] === "Exterior" ? soilTypesEdited[tabValue] : indoorSystemsEdited[tabValue]}
							onChange={handleSoilSystemChange}
							label={placesEdited[tabValue] === "Exterior" ? "Tipo de suelo":"Tipo de Sistema"}
							labelWidth={1}
							size="small"
							>
								{placesEdited[tabValue] === "Exterior" && typeOfSoil.map((soil,index) => (
									<MenuItem key={index} value={soil}>{soil}</MenuItem>
								))}

								{placesEdited[tabValue] === "Interior" && typeOfSystem.map((system,index) => (
									<MenuItem key={index} value={system}>{system}</MenuItem>
								))}
							
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={{justifyContent:"center",alignItems:"center",textAlign:"center"}}>
							<label className={"mt-3"}>Semana de {phenoStagesEdited[tabValue]}: {weeksEdited[tabValue]}</label>
						</div>
						
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={{justifyContent:"center",alignItems:"center",textAlign:"center"}}>
							<label className={"mt-3"}>Día de {phenoStagesEdited[tabValue]}: {daysEdited[tabValue]}</label>
						</div>
					</Grid>
				</Grid>
				<div style={{marginTop: "15px", marginBottom: "15px"}}> 
					<Divider />
				</div>

				<Grid container justifyContent='center' alignItems='center'>
					<Grid item xs={12} sm={12}>
						<div style={{ textAlign: 'center'}}>
							<label className={"mt-3"}>Ubicacion del Cultivo:</label>
						</div>
						
					</Grid>
					<Grid item xs={12} sm={12}>
						<MapForCrop setCurrentCoordinates={handleNewCoordinates} initialCoordinates={currentCoordinates[tabValue]}/>
					</Grid>
				</Grid>

				<div style={{marginTop: "15px", marginBottom: "15px"}}> 
					<Divider />
				</div>

				<Grid container justifyContent='center' alignItems='center'>
					
					
					{isLoadingEdit ? <CircularProgress color="primary" />
					: 
					<Button 
					variant="contained" 
					color="primary" 
					className={classes.button}	
					onClick={saveChanges}
					disabled={namesEdited[tabValue] === "" || daysEdited[tabValue] === "" || 
						weeksEdited[tabValue] === "" || isLoadingDelete}
					>
						Editar
					</Button>
					}
				</Grid>
			</DialogContent>
		</Dialog>
	</div>
  )
}
